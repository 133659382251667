
export default {
  name: `AreYouSurePopup`,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ``
    },
    message: {
      type: String,
      default: ``
    },
    noText: {
      type: String,
      default: ``
    },
    yesText: {
      type: String,
      default: ``
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit(`input`, val)
      }
    }
  },
  methods: {
    cancel() {
      this.visible = false
      this.$emit(`cancel`)
    },
    yesDelete() {
      this.visible = false
      this.$emit(`yesDelete`)
    }
  }
}
